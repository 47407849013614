<template>
  <div>
    <v-card 
      outlined 
      min-height="100%"
      :class="[!$vuetify.breakpoint.smAndDown ? 'py-0 pt-0 pa-0' : 'd-flex justify-space-between py-0', !not_rounded ? 'rounded-xl' : 'rounded']" 
    >    
      <div v-if="flag" :class="[title_class, 'd-flex', 'justify-center', 'mr-0', 'px-3']">
        <country-flag class="ma-0 pa-0 mb-n1 mt-n1" v-if="flag != 'other' && !$vuetify.breakpoint.mobile" :country="flag"  size="normal"></country-flag> 
        <v-icon class="mt-0 mr-2" v-if="flag == 'other' && !$vuetify.breakpoint.mobile" size="28" color="grey">mdi-diving-scuba-flag</v-icon>
        <div class="mt-0 ml-0">{{title}}</div>
      </div>

      <div v-if="title_with_icon" :class="[title_class, 'px-4', 'd-flex', 'justify-center']">
        <v-icon :size="!$vuetify.breakpoint.smAndDown ? 20 : 17 " class="pt-0" :color="icon_color">{{icon}}</v-icon>
        <div class="mt-0 ml-0 px-2" style="white-space: nowrap;">{{title}}</div>
      </div>
      <slot name='main_data'>
        <div class="text-center">
          <span class="headline mt-0 font-weight-black main-data mr-2">{{Number(value || 0).toLocaleString('pt-BR')}}</span> 
        </div>
      </slot>

      <slot name='extra'></slot>

    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'DefaultCountCard',

    props: {
      value: {
        type: Number,
      },
      title: {
        type: String,
      },
      icon: {
        type: String,
      },
      title_class:{
        type: String,
      },
      icon_color:{
        type: String,
        default: 'primary'
      },
      flag:{
        type: String,
        default: null
      },
      title_with_icon:{
        type: Boolean,
        default: true
      },
      not_rounded:{
        type: Boolean,
        default: false
      }
      
    },

    methods: {
      formatMoney(entry){
        return (Number(entry)/100).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
      }
    },
    
  }
</script>

<style lang="scss" scoped>
  @media (max-width: 500px) {
    .main-data{
      font-size: 5vw!important;
      margin-left: -25px!important;
    }
  }
</style>